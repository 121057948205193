/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense, useEffect } from 'react';

import './App.css';
import { Switch, Route, useLocation } from 'react-router-dom';
import $ from 'jquery';

// index.js or App.js
import 'bootstrap/dist/css/bootstrap.min.css';

import UpButton from './Dashboard/Pages/Home/Components/Up_Button/Up_Button';

// PAGES
const Home = lazy(() => import('./Dashboard/Pages/Home/Home'));
const SeaboardCompanies = lazy(() => import('./Dashboard/Pages/Seaboard_Companies/Seaboard_Companies'));
const CareerForm = lazy(() => import('./Dashboard/Pages/CareerForm/CareerForm'));
const Contact = lazy(() => import('./Dashboard/Pages/Contact/Contact'));
const SeaBoardFamily = lazy(() => import('./Dashboard/Pages/SeaboardFamily/SeaBoardFamily'));
const Values = lazy(() => import('./Dashboard/Pages/Values/Values'));
const Rashion = lazy(()=>import('./Dashboard/Pages/RationPage/Rahion') )



const App = () => {

  const location = useLocation();


  useEffect(() => {
    $('.loading-screen').removeClass('fading');
    setTimeout(() => {
      $('.loading-screen').addClass('fading');
    }, );
  }, [location])

  return (
    <>
      <div className="loading-screen fading"><div>Seaboard</div></div>
          <UpButton />

      <Switch>

        <Route
          exact
          path="/"
          render={
            () => <Suspense fallback={ <div className="loading-screen fading"><div>Seaboard</div></div>}><Home /></Suspense>
          }
        />
  
        <Route
          exact
          path="/Seaboard_Companies"
          render={
            () => <Suspense fallback={ <div className="loading-screen fading"><div>Seaboard</div></div>}><SeaboardCompanies /></Suspense>
          }
        />
        <Route
          exact
          path="/careers"
          render={
            () => <Suspense fallback={ <div className="loading-screen fading"><div>Seaboard</div></div>}><CareerForm /></Suspense>
          }
        />
        <Route
          exact
          path="/contact"
          render={
            () => <Suspense fallback={ <div className="loading-screen fading"><div>Seaboard</div></div>}><Contact /></Suspense>
          }
        />
        <Route
          exact
          path="/seaboard_family"
          render={
            () => <Suspense fallback={ <div className="loading-screen fading"><div>Seaboard</div></div>}><SeaBoardFamily /></Suspense>
          }
        />
        <Route
          exact
          path="/values"
          render={
            () => <Suspense fallback={ <div className="loading-screen fading"><div>Seaboard</div></div>}><Values /></Suspense>
          }
        />
    <Route
          exact
          path="/ration"
          render={
            () => <Suspense fallback={ <div className="loading-screen fading"><div>Seaboard</div></div>}><Rashion /></Suspense>
          }
        />

      </Switch>


    </>
  )

}

export default App;
